.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    margin: 0 auto;
}

.content {
    width: 600px;
    margin-left: 200px;
}

.nodata {
    margin-left: 150px;
    margin-top: 100px;
    color: #e6e6e6;
}

.InboxOutlined {
    font-size: 50px;
}

.row {
    display: flex;
    align-items: center;
}

.col {
    margin-left: 0;
    width: 350px;
}

.title {
    font-size: 30;
    text-align: center;
}

.inputAdd {
    width: 400px;
}

.checkrow {
    margin-right: 30px;
}

.finish {
    color: black;
    text-decoration: line-through;
}

.overtime {
    color: red;
}

.intime {
    color: black
}